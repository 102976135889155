
img.avatar {
  width: 100px;
  border:solid 2px white;
  border-radius:5px;

  &:hover{
    border-color: gray;
    cursor: pointer;
  }
  &.active{
    border-color: #38f;
  }
}
