
.players {
  .player {
    position: absolute;
    width:1px;
    height:1px;

    .name {
      position: absolute;
      left: 10px;
      bottom:0;
      display: inline-block;
      color:#fff;
      text-transform: uppercase;
      font-size:10px;
      white-space: nowrap;
      font-family: "Open Sans", sans-serif;
      font-weight: 900;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }

    .avatar {
      position: absolute;
      bottom: 0;
      right: 0;
      border:0;
      width: 100px;
    }

    .fire {
      position: absolute;
      bottom: 0;
      right: 0;
      border:0;
      width: 100px;
    }

    &.beginning {
      .name {
        left: -80px;
        top:0px;
        width: 100px;
        text-align: center;
      }
    }

    &.floating-player {
      .avatar {
        animation: floating-player-animation 6s ease-in-out infinite;
      }
    }
  }
}

@keyframes floating-player-animation {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
