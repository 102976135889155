
.ticker {
  position: absolute;
  top: 90.5%;
  bottom: 1.5%;
  right: 17%;
  left: 1.5%;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  overflow: hidden;
  font-size:32px;

  .question {
    color:#fff;
  }

  .answer {
    color:#fff;
  }

  .points {
    color:#5bf;
    font-size:24px;
  }

  .player-name {
    color:#000;
    background-color: #666;
    font-size:18px;
    padding:5px 8px;
    border-radius: 6px;
    margin-right: 3px;
  }

  .nobody {

  }

  .separator {
    color:#c44;
  }
}
.fade-leave-active {
  transition: opacity .5s
}
.fade-leave-to {
  opacity: 0
}