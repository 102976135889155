
.scoreboard {
  position: absolute;
  right: 19.5%;
  left: 21%;
  color:#fff;
  font-size:22pt;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  overflow: hidden;

  h1, h2, h3 {
    line-height: 1em;
  }

  .league-name {
    position: absolute;
    right:0;
    top:0;
    text-align: right;
    color:#666;
    text-transform: uppercase;
    font-size:11px;
    background-color: #111;
  }

  .full-layout {
    position: absolute;
    top:3%;
    left:3%;
    right:3%;
    bottom:3%;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .avatar-layout {
    > .avatar {
      position: absolute;
      top:0;
      left:0;
      right:70%;
      bottom:0;
      overflow: hidden;
      background-size: 140%;
      background-position: 100% 0;
    }

    > .body {
      position: absolute;
      top:0;
      left:35%;
      right:3%;
      bottom:0;
      overflow: hidden;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .upcoming-answers-table td {
    padding:0.1em 3px;
  }
}

body.olympics .scoreboard {
  top: 4%;
  bottom: 61%;
  left: 4%;
  right: 37%;
}

body.superbowl .scoreboard {
  top: 4%;
  bottom: 62%;
}

body.oscars .scoreboard {
  top: 6.2%;
  bottom: 59.8%;
}
