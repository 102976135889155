
.answers-scoreboard {
  width:1280px;
  height:720px;
  position: relative;
  background-color:#000;
  color:#fff;
  font-size:22pt;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
