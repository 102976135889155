
body {
  background-color:#000;
}

.bg {
  position:absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  background-size: 100% 100%;
}

body.olympics .bg {
  background-image: url(/public/scoreboard/bg-olympics.jpg);
}

body.superbowl .bg {
  background-image: url(/public/scoreboard/bg-superbowl.jpg);
}

body.oscars .bg {
  background-image: url(/public/scoreboard/bg-oscars.jpg);
}
