

.fit-to-parent {
  opacity: 0;
  transition: none;

  &.show {
    opacity: 1;
    transition:opacity 500ms;
  }
}
